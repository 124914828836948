import { PATH } from '../../../../constants/global'
import { TABLE } from '../../../../constants/tables'

export const COSTING_TABLE = {
   basicPath: PATH.COSTING_HISTORY,
   downloadCsvText: 'Export to Excel',
   extraClassName: 'table-basic',
   header: 'Title',
   key: 'CostingData',
   modalTitle: 'Download Excel File',
   placeholder: 'costing history',
   tableTitle: TABLE.TITLE.COSTING_HISTORY,
   primaryActionText: 'Export',
   secondaryActionText: 'Cancel',
   exportFileName: 'CostingHistory.xlsx',
   initialPayload: {
      type: 0,
      startDate: null,
      endDate: null,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      onlyToolsMarkedAsRental: false,
      offset: null,
      skip: 0,
      take: 10,
      query: '',
   },
   downloadRequest: {
      type: 0,
      startDate: '',
      endDate: '',
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      onlyToolsMarkedAsRental: false,
      offset: null,
      skip: 0,
      take: 10,
      query: null,
   },
   defaultColumnOrder: [
      'BorrowingUserDisplayName',
      'ToolLoanLocation',
      'Title',
      'ToolTypeDesc',
      'Quantity',
      'Description',
      'ManufacturerName',
      'ModelNumber',
      'SerialNumber',
      'ItemBarcode',
      'CategoryName',
      'PurchaseDate',
      'PurchasePrice',
      'WarrantyDate',
      'IsRentalTool',
      'RentalDate',
      'RentalReturnDate',
      'CustomRentalRate1',
      'CustomRentalRate2',
      'CustomRentalRate3',
      'CustomRentalRate4',
      'CustomDate1Value',
      'CustomDate2Value',
      'CustomText1Value',
      'CustomText2Value',
      'CustomURL1Value',
      'CustomURL2Value',
      'WebsiteUrl',
      'AttachmentUrl',
      'DefaultLocation',
      'DateAccepted',
      'PlannedLoanReturnDisplay',
      'ReturnDate',
      'ReportStartDate',
      'ReportEndDate',
      'DurationDays',
      'DurationTimeHrs',
      'LoanDuration',
   ],
}
